/* -----------------------------------------------------------------------------
  BODY
*/

body {
  --x-linear-scale-min-value: var(--x-default-min-font-size);
  --x-linear-scale-max-value: var(--x-default-max-font-size);

  @include linear-scale((font-size));
  background-color: var(--x-white);
  color: var(--x-body-color);
  font-feature-settings: "liga", "kern";
  font-kerning: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: var(--x-line-height-base);
  margin: 0;
  min-width: var(--x-body-min-width);
  overflow-y: auto;
  -webkit-tap-highlight-color: transparent;
  text-align: left;
  text-rendering: geometricPrecision;
  text-size-adjust: 100%;
}

/* -----------------------------------------------------------------------------
  HEADING
*/

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  color: var(--x-black);
  line-height: 1.1;
  margin-top: 0;

  small {
    font-size: 75%;
  }
}

h1,
.h1 {
  --x-linear-scale-min-value: var(--x-h1-min-font-size);
  --x-linear-scale-max-value: var(--x-h1-max-font-size);

  @include linear-scale((font-size));
  margin-bottom: calc(var(--x-spacer) * 2);
  text-transform: uppercase;
}

h2,
.h2 {
  --x-linear-scale-min-value: var(--x-h2-min-font-size);
  --x-linear-scale-max-value: var(--x-h2-max-font-size);

  @include linear-scale((font-size));
  margin-bottom: var(--x-spacer);
  text-transform: uppercase;
}

h3,
.h3 {
  --x-linear-scale-min-value: var(--x-h3-min-font-size);
  --x-linear-scale-max-value: var(--x-h3-max-font-size);

  @include linear-scale((font-size));
  margin-bottom: var(--x-spacer);
}

h4,
.h4 {
  --x-linear-scale-min-value: var(--x-h4-min-font-size);
  --x-linear-scale-max-value: var(--x-h4-max-font-size);

  @include linear-scale((font-size));
  line-height: var(--x-line-height-base);
  margin-bottom: var(--x-spacer);
}

h5,
.h5 {
  --x-linear-scale-min-value: var(--x-h5-min-font-size);
  --x-linear-scale-max-value: var(--x-h5-max-font-size);

  @include linear-scale((font-size));
  line-height: var(--x-line-height-base);
  margin-bottom: var(--x-spacer);
}

/* -----------------------------------------------------------------------------
  PARAGRAPGS
*/

p {
  margin-bottom: rem(14);
  margin-top: 0;
}

/* -----------------------------------------------------------------------------
  LINKS
*/

a {
  background-color: transparent;
  color: var(--x-anchor-color);
  fill: var(--x-anchor-color);
  text-decoration: none;

  &:focus {
    outline: rem(2) solid var(--x-anchor-focus-color);
    outline-offset: rem(1);
    position: relative;
    text-decoration: none;
    z-index: 3000;
  }

  &:hover {
    --x-anchor-color: var(--x-anchor-hover-color);
  }

  &.disabled {
    opacity: 0.65;
    pointer-events: none;
  }

  &[tabindex="-1"],
  &[data-focus-method="mouse"],
  &[data-focus-method="touch"] {
    outline: 0;
  }

  &:not([href]) {
    --x-anchor-color: var(--x-body-color);
  }
}

/* -----------------------------------------------------------------------------
  HORIZONTAL RULES
*/

hr {
  border: 0;
  border-top: var(--x-border-width) solid var(--x-gray-400);
  box-sizing: content-box;
  height: 0;
  margin-bottom: rem(16);
  margin-top: rem(16);
  overflow: visible;
}

/* -----------------------------------------------------------------------------
  EMPHASIS
*/

small,
.small {
  font-size: var(--x-small-font-size);
}

abbr[title],
abbr[data-original-title] {
  border-bottom: 0;
  cursor: help;
  text-decoration: none;
}

/* -----------------------------------------------------------------------------
  LISTS
*/

ol,
ul {
  margin-bottom: rem(16);
  margin-top: 0;

  ol,
  ul {
    margin-bottom: 0;
  }
}

dl {
  margin-bottom: rem(16);
  margin-top: 0;
}

.list-styled {
  list-style: escape-svg(url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10'><rect fill='#{$primary}' height='7' width='7' x='0' y='1'/></svg>"));
  margin-left: 0;
  padding-left: rem(17);
}

.list-unstyled {
  list-style: none;
  padding-left: 0;
}

/* -----------------------------------------------------------------------------
  SUB AND SUP
*/

sub,
sup {
  font-size: var(--x-sub-sup-font-size);
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: em(-7);
}

sup {
  top: em(-4);
}
