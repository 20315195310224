/* -----------------------------------------------------------------------------
  PAGINATION
*/

.pagination {
  @include list-unstyled();
  border-radius: var(--x-border-radius);
  display: flex;
}

.page-link {
  background-color: var(--x-pagination-bg-color);
  border: var(--x-border-width) solid var(--x-pagination-border-color);
  color: var(--x-pagination-color);
  cursor: pointer;
  display: block;
  line-height: var(--x-pagination-line-height);
  margin-left: calc(var(--x-border-width) * -1);
  padding: var(--x-pagination-padding-y) var(--x-pagination-padding-x);
  position: relative;
  user-select: none;

  &:hover {
    --x-pagination-bg-color: var(--x-pagination-hover-bg-color);
    --x-pagination-border-color: var(--x-pagination-hover-border-color);
    --x-pagination-color: var(--x-pagination-hover-color);

    text-decoration: none;
    z-index: 2;
  }

  &:focus {
    --x-pagination-border-color: var(--x-pagination-focus-border-color);

    box-shadow: 0 0 0 rem(3) var(--x-pagination-focus-box-shadow-color);
    outline: 0;
    z-index: 3;
  }
}

.page-item {
  &:first-child {
    .page-link {
      border-bottom-left-radius: rem(4);
      border-top-left-radius: rem(4);
      margin-left: 0;
    }
  }

  &:last-child {
    .page-link {
      border-bottom-right-radius: rem(4);
      border-top-right-radius: rem(4);
    }
  }

  &.active .page-link {
    --x-pagination-bg-color: var(--x-pagination-active-bg-color);
    --x-pagination-border-color: var(--x-pagination-active-border-color);
    --x-pagination-color: var(--x-pagination-active-color);

    z-index: 3;
  }

  &.disabled .page-link {
    --x-pagination-bg-color: var(--x-pagination-disabled-bg-color);
    --x-pagination-border-color: var(--x-pagination-disabled-border-color);
    --x-pagination-color: var(--x-pagination-disabled-color);

    cursor: auto;
    pointer-events: none;
  }
}
