$project-name: Vincent;
$brand-1: #FCAF16;
$brand-2: #007534;
$chart-1: #D40EEF;
$danger: #DC3545;
$event-dry-chamber: #85412E;
$favicon-bg-color: #007534;
$gray-600: #868E96;
$gray-800: #343A40;
$gray-900: #212529;
$info: #17A2B8;
$success: #90AD25;
$warning: #FFC107;
$white: #FFFFFF;