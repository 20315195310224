/* -----------------------------------------------------------------------------
  FIELDSET
*/

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

legend {
  --x-linear-scale-min-value: var(--x-legend-min-font-size);
  --x-linear-scale-max-value: var(--x-legend-max-font-size);

  @include linear-scale((font-size));
  color: inherit;
  cursor: default !important; // stylelint-disable declaration-no-important
  display: block;
  line-height: inherit;
  margin-bottom: var(--x-legend-margin-bottom);
  max-width: 100%;
  padding: 0;
  white-space: normal;
  width: 100%;

  &.label {
    margin-bottom: 0;
  }
}
