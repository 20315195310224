/* -----------------------------------------------------------------------------
  TOASTS
*/

.toast-wrapper {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless) / 2;
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless) / 2;

  @include linear-scale((right));
  bottom: calc(var(--x-spacer) * 4);
  position: fixed;
  z-index: 2000;
}

.toast {
  font-size: var(--x-toast-font-size);
  max-width: var(--x-toast-max-width);
  opacity: 0;
  text-align: right;

  &:not(:last-child) {
    margin-bottom: var(--x-toast-padding-x);
  }

  &.showing {
    opacity: 1;
  }

  &.show {
    display: block;
    opacity: 1;
  }

  &.hide {
    display: none;
  }
}

.toast-content {
  background-clip: padding-box;
  background-color: var(--x-toast-background-color);
  border: var(--x-toast-border-width) solid var(--x-toast-border-color);
  border-radius: var(--x-border-radius);
  box-shadow: 0 rem(8) rem(16) rgba($black, 0.15);
  display: inline-flex;
  flex-direction: row-reverse;
  overflow: hidden;
  text-align: left;
}

.toast-header {
  align-items: center;
  background-clip: padding-box;
  background-color: var(--x-toast-header-background-color);
  border-bottom: var(--x-toast-border-width) solid var(--x-toast-header-border-color);
  color: var(--x-toast-header-color);
  display: flex;
  padding: var(--x-toast-padding-y) var(--x-toast-padding-x);
}

.toast-body {
  margin-left: auto;
  padding: var(--x-toast-padding-x);
}
