// stylelint-disable declaration-no-important

/* -----------------------------------------------------------------------------
  BASE STYLES
*/

.btn {
  @extend %btn;
}

.btn-lg {
  font-size: var(--x-input-font-size-lg);
  line-height: calc(var(--x-line-height-base) * 1.2);
  padding: var(--x-button-padding-y) var(--x-button-padding-x);
}

.btn-sm {
  font-size: var(--x-input-font-size-sm);
  line-height: calc(var(--x-line-height-base) * 0.8);
  padding: var(--x-button-padding-y) var(--x-button-padding-x);
}

/* -----------------------------------------------------------------------------
  ALTERNATE BUTTONS
*/

@each $color, $value in $colors {
  .btn-#{$color} {
    @include button-variant($value);
  }
}

/* -----------------------------------------------------------------------------
  LOADER
*/

.btn-loader {
  color: transparent !important;
  cursor: default;
  fill: transparent !important;
  pointer-events: none;
  position: relative;

  .spinner-border-sm {
    left: 50%;
    margin-left: calc(var(--x-spinner-height-sm) / -2);
    margin-top: calc(var(--x-spinner-height-sm) / -2);
    position: absolute;
    top: 50%;
  }
}
