/* -----------------------------------------------------------------------------
  CONTAINER
*/

.container-fluid {
  @include make-container();
}

.container-max-fluid {
  @extend .container-fluid;
  max-width: var(--x-container-xl-max-width);
}

.container {
  @extend .container-fluid;
  @include make-container-max-widths();
}

/* -----------------------------------------------------------------------------
  ROW
*/

.row {
  @include make-row();

  > * {
    @include make-col-ready();
  }
}

.sm-gutters {
  @include make-row($divider: 4);
}

.no-gutters {
  margin-left: 0;
  margin-right: 0;

  > * {
    padding-left: 0;
    padding-right: 0;
  }
}

/* -----------------------------------------------------------------------------
  COLUMNS
*/

@include make-grid-columns();
