.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: var(--x-breadcrumb-margin-bottom);
  padding: 0;
}

.breadcrumb-item {
  + .breadcrumb-item {
    padding-left: var(--x-breadcrumb-item-padding-x);

    &::before {
      color: var(--x-breadcrumb-divider-color);
      content: "/";
      float: left; // Suppress inline spacings and underlining of the separator
      padding-right: var(--x-breadcrumb-item-padding-x);
    }
  }

  &.active {
    color: var(--x-breadcrumb-active-color);
  }
}
