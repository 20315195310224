/* -----------------------------------------------------------------------------
  COLORS
*/

@import "project.json";

// Change default colors in project.json
$brand-1: #EE7F01 !default;
$brand-2: #006893 !default;

$white: #FFF;
$black: #000;

$gray-100: #F8F9FA;
$gray-200: #E9ECEF;
$gray-300: #DEE2E6;
$gray-400: #CED4DA;
$gray-500: #ADB5BD;
$gray-600: #868E96;
$gray-700: #495057;
$gray-800: #343A40;
$gray-900: #212529;

$primary: $brand-1;
$secondary: $brand-2;
$tertiary: $brand-2;

$danger: #DC3545 !default;
$dark: $gray-900 !default;
$info: #17A2B8 !default;
$light: $gray-100 !default;
$success: #90AD25 !default;
$warning: #FFC107 !default;

$quality_industry: #90AD25 !default;
$quality_visual: #EEE728 !default;

$colors: (
  "black": $black,
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "danger": $danger,
  "dark": $dark,
  "info": $info,
  "light": $light,
  "primary": $primary,
  "primary-transparent": transparentize($primary, 0.7),
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "warning": $warning,
  "white": $white,
  "quality-industry": $quality_industry,
  "quality-visual": $quality_visual
);

/* -----------------------------------------------------------------------------
  FORMS
*/

// File

$input-file-text: (
  de: "Durchsuchen",
  en: "Browse",
);

// Button

$button-pressed-bg-color: $tertiary;
$badge-pressed-bg-color: $white;

/* -----------------------------------------------------------------------------
  COLUMN
*/

$columns: 6;

/* -----------------------------------------------------------------------------
  BREAKPOINTS
*/

$body-min-width: 320;

$break-sm: 480;
$break-md: 768;
$break-lg: 992;
$break-xl: 1400;

$grid-breakpoints: (
  xs: 0,
  sm: rem($break-sm),
  md: rem($break-md),
  lg: rem($break-lg),
  xl: rem($break-xl),
);

/* -----------------------------------------------------------------------------
  GRID
*/

$grid-columns: 12;

$container-max-widths: (
  xs: 0,
  sm: rem($break-sm),
  md: rem($break-md),
  lg: rem($break-lg),
  xl: rem($break-xl),
);

/* -----------------------------------------------------------------------------
  COMPONENTS
*/

// Tooltip

$tooltip-arrow-color: $tertiary;
