/* -----------------------------------------------------------------------------
  RESETS
*/

table {
  border-collapse: collapse;
}

th,
td {
  padding: 0;
  text-align: inherit;
}


/* -----------------------------------------------------------------------------
  TABLE
*/

.table {
  border-bottom: var(--x-table-border-width) solid var(--x-table-border-color);
  color: var(--x-table-color);
  margin-bottom: calc(var(--x-spacer) * 2);
  max-width: 100%;
  width: 100%;

  > :not(caption) > * > * {
    background-color: var(--x-table-bg-color);
    border-top: var(--x-table-border-width) solid var(--x-table-border-color);
    padding: var(--x-table-cell-padding);
    vertical-align: top;

    &:first-child {
      padding-left: calc(var(--x-table-cell-padding) * 2);
    }

    &:last-child {
      padding-right: calc(var(--x-table-cell-padding) * 2);
    }
  }

  > thead > * > th {
    border-bottom: calc(var(--x-table-border-width) * 2) solid var(--x-table-border-color);
    border-top: 0;
    position: relative;
    vertical-align: bottom;
  }

  > tfoot > * td {
    border-top: calc(var(--x-table-border-width) * 2) solid var(--x-table-border-color);
  }
}

.table-sm {
  > :not(caption) > * > * {
    padding: var(--x-table-cell-padding-sm);

    &:first-child {
      padding-left: calc(var(--x-table-cell-padding-sm) * 2);
    }

    &:last-child {
      padding-right: calc(var(--x-table-cell-padding-sm) * 2);
    }
  }
}

/* -----------------------------------------------------------------------------
  TABLE: LAYOUT
*/

.table-layout-fixed {
  table-layout: fixed;
}

/* -----------------------------------------------------------------------------
  TABLE: BORDERLESS
*/

.table-borderless {
  border: 0;

  > :not(caption) > * > *,
  > thead > * > th {
    border: 0;
  }
}

/* -----------------------------------------------------------------------------
  TABLE: STRIPING
*/

.table-striped {
  > tbody > tr:nth-of-type(even) > * {
    background-color: var(--x-table-striped-bg-color);
    color: var(--x-table-striped-color);
  }
}

/* -----------------------------------------------------------------------------
  TABLE: HOVER
*/

.table-hover {
  > tbody > tr:hover > * {
    background-color: var(--x-table-hover-bg-color);
    color: var(--x-table-hover-color);
  }
}

/* -----------------------------------------------------------------------------
  TABLE: BACKGROUND
*/

@each $color, $value in $colors {
  .table-#{$color} {
    @include table-variant(color-level($value, -9), color-level($value, -6));
  }
}


/* -----------------------------------------------------------------------------
  TABLE: RESPONSIVE
*/

@each $breakpoint in map-keys($grid-breakpoints) {
  $next: breakpoint-next($breakpoint);
  $infix: breakpoint-infix($next);

  @include media-breakpoint-down($breakpoint) {
    .table-responsive#{$infix} {
      -webkit-overflow-scrolling: touch;
      overflow-x: auto;

      > .table-bordered {
        border: 0;
      }
    }
  }
}
