/* -----------------------------------------------------------------------------
  SPINNERS
*/

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  animation: spinner-border var(--x-spinner-animation-speed) linear infinite;
  border: var(--x-spinner-border-width) solid var(--x-spinner-color);
  border-radius: 50%;
  border-right-color: transparent;
  display: inline-block;
  height: var(--x-spinner-height);
  vertical-align: text-bottom;
  width: var(--x-spinner-width);
}

.spinner-border-sm {
  border-width: var(--x-spinner-border-width-sm);
  height: var(--x-spinner-height-sm);
  width: var(--x-spinner-width-sm);
}
