%btn {
  background-color: var(--x-button-bg-color);
  border: var(--x-border-width) solid var(--x-button-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-button-color);
  cursor: pointer;
  display: inline-block;
  fill: var(--x-button-color);
  font-family: inherit;
  font-size: var(--x-input-font-size);
  line-height: var(--x-line-height-base);
  padding: var(--x-button-padding-y) var(--x-button-padding-x);
  text-align: center;
  transition: background-color 150ms ease-in-out, border-color 150ms ease-in-out, box-shadow 150ms ease-in-out;
  user-select: none;
  vertical-align: middle;
  white-space: nowrap;

  &:focus,
  &.focus {
    box-shadow: 0 0 0 rem(3) var(--x-button-box-shadow-color);
    outline: 0;
  }

  &:hover {
    text-decoration: none;
  }

  &.disabled,
  &:disabled {
    opacity: 1;
    pointer-events: none;
  }

  &[aria-pressed="true"] {
    @include button-variant($button-pressed-bg-color);

    .badge {
      @include badge-variant($badge-pressed-bg-color);
    }
  }
}
