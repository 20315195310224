// stylelint-disable declaration-no-important

/* -----------------------------------------------------------------------------
  GENERAL
*/

.custom-control {
  display: block;
  min-height: calc(rem($input-font-size) * var(--x-line-height-base));
  padding-left: calc(var(--x-input-control-gutter) + var(--x-input-control-indicator-size));
  position: relative;
}

.custom-control-label {
  margin-bottom: 0;
  margin-top: calc(var(--x-input-padding-y) + var(--x-input-border-width));
  position: relative;
  vertical-align: top;

  // Input
  &::before {
    background-color: var(--x-white);
    border: var(--x-border-width) solid var(--x-input-border-color);
    content: "";
    display: block;
    height: var(--x-input-control-indicator-size);
    left: calc((var(--x-input-control-indicator-size) + var(--x-input-control-gutter)) * -1);
    pointer-events: none;
    position: absolute;
    top: calc((var(--x-input-font-size) * var(--x-line-height-base) - var(--x-input-control-indicator-size)) / 3);
    transition: border-color ease-in-out 150ms, box-shadow ease-in-out 150ms;
    width: var(--x-input-control-indicator-size);
  }

  // Indicator
  &::after {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 50%;
    content: "";
    display: block;
    height: var(--x-input-control-indicator-size);
    left: calc((var(--x-input-control-indicator-size) + var(--x-input-control-gutter)) * -1);
    position: absolute;
    top: calc((var(--x-input-font-size) * var(--x-line-height-base) - var(--x-input-control-indicator-size)) / 3);
    width: var(--x-input-control-indicator-size);
  }
}

.custom-control-input {
  opacity: 0;
  position: absolute;
  z-index: -1;

  &:checked ~ .custom-control-label::before {
    background-color: var(--x-input-focus-border);
    border-color: var(--x-input-focus-border);
  }

  &:focus ~ .custom-control-label::before {
    border-color: var(--x-input-focus-border);
    box-shadow: 0 0 0 rem(3) var(--x-input-focus-box-shadow-color);
  }

  &[disabled] ~ .custom-control-label,
  &[readonly] ~ .custom-control-label {
    color: var(--x-gray-600);
    pointer-events: none;

    &::before {
      background-color: var(--x-input-disabled-bg-color);
      border-color: var(--x-input-border-color);
    }
  }

  &:checked[disabled] ~ .custom-control-label {
    &::after {
      background-image: escape-svg(url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$gray-600}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>")) !important;
    }
  }
}

.custom-control-inline {
  display: inline-flex;
  margin-right: rem(16);
}

/* -----------------------------------------------------------------------------
  CHECKBOX
*/

.custom-checkbox {
  .custom-control-label::before {
    border-radius: var(--x-border-radius);
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg(url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'><path fill='#{$white}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/></svg>"));
    }
  }
}

/* -----------------------------------------------------------------------------
  RADIO
*/

.custom-radio {
  .custom-control-label::before {
    border-radius: 50%;
  }

  .custom-control-input:checked ~ .custom-control-label {
    &::after {
      background-image: escape-svg(url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'><circle r='3' fill='#{$white}'/></svg>"));
    }
  }
}
