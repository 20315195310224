/* -----------------------------------------------------------------------------
 PROGRESS
*/

@keyframes progress-bar-stripes {
  0% {
    background-position-x: var(--x-progress-height);
  }
}

.progress {
  background-color: var(--x-progress-bg-color);
  border-radius: var(--x-border-radius);
  display: flex;
  font-size: rem(12);
  height: var(--x-progress-height);
  overflow: hidden;
}

.progress-bar {
  background-color: var(--x-progress-bar-bg-color);
  color: var(--x-progress-bar-color);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  transition: width 600ms ease;
  white-space: nowrap;
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba($white, 0.15) 25%, transparent 25%, transparent 50%, rgba($white, 0.15) 50%, rgba($white, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--x-progress-height) var(--x-progress-height);
}

.progress-bar-animated {
  animation: progress-bar-stripes var(--x-progress-bar-animation-timing);

  @media (prefers-reduced-motion: reduce) {
    animation: none;
  }
}
