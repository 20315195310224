/* -----------------------------------------------------------------------------
  FULL CALENDAR
*/

.fc {
  .fc-button {
    @extend %btn;
  }

  .fc-button-primary {
    @include button-variant($gray-300);

    .fc-icon {
      background: color-yiq($gray-300);
    }

    &:focus,
    &:hover {
      .fc-icon {
        background: color-yiq(darken($gray-300, 5%));
      }
    }

    &:not(:disabled):active,
    &:not(:disabled).fc-button-active {
      @include button-variant($tertiary);

      .fc-icon {
        background: color-yiq($tertiary);
      }

      &:focus,
      &:hover {
        .fc-icon {
          background: color-yiq(darken($tertiary, 5%));
        }
      }
    }
  }

  .fc-calendar-item {
    padding: 0 rem(2);
  }
}
