/* -----------------------------------------------------------------------------
  FILE
*/

.custom-file {
  display: inline-block;
  height: var(--x-input-height);
  margin-bottom: 0;
  position: relative;
  width: 100%;
}

.custom-file-input {
  cursor: pointer;
  height: var(--x-input-height);
  margin: 0;
  opacity: 0;
  position: relative;
  width: 100%;
  z-index: 2;

  &:focus ~ .custom-file-label {
    border-color: var(--x-input-focus-border);
    box-shadow: 0 0 0 rem(3) var(--x-input-focus-box-shadow-color);
  }

  &:disabled ~ .custom-file-label {
    background-color: var(--x-input-disabled-bg-color);
  }

  @each $lang, $value in $input-file-text {
    &:lang(#{$lang}) ~ .custom-file-label::after {
      content: $value;
    }
  }

  ~ .custom-file-label[data-browse]::after {
    content: attr(data-browse);
  }
}

.custom-file-label {
  background-color: var(--x-input-bg-color);
  border: var(--x-input-border-width) solid var(--x-input-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-input-color);
  font-size: var(--x-input-font-size);
  height: var(--x-input-height);
  left: 0;
  line-height: var(--x-input-line-height);
  overflow: hidden;
  padding: var(--x-input-padding-y) var(--x-input-padding-x);
  position: absolute;
  right: 0;
  top: 0;
  white-space: nowrap;
  z-index: 1;

  &::after {
    background: var(--x-gray-200);
    border-left: inherit;
    border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
    bottom: 0;
    color: var(--x-input-color);
    display: block;
    height: var(--x-input-height-inner);
    line-height: var(--x-input-line-height);
    padding: var(--x-input-padding-y) var(--x-input-padding-x);
    position: absolute;
    right: 0;
    top: 0;
    z-index: 3;
  }
}
