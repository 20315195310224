/* -----------------------------------------------------------------------------
  INPUT GROUP
*/

.input-group {
  align-items: stretch;
  display: flex;
  position: relative;
  width: 100%;

  > .form-control {
    flex: 1 1 0%;
    margin-bottom: 0;
    min-width: 0;
    position: relative;
  }

  > .form-control:focus {
    z-index: 3;
  }

  > .form-control {
    &:not(:last-child) {
      border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
    }

    &:not(:first-child) {
      border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
    }
  }
}

.input-group-prepend,
.input-group-append {
  display: flex;
}

.input-group-prepend {
  margin-right: calc(var(--x-input-border-width) * -1);
}

.input-group-append {
  margin-left: calc(var(--x-input-border-width) * -1);
}

.input-group-text {
  align-items: center;
  background-color: var(--x-input-group-addon-bg-color);
  border: var(--x-border-width) solid var(--x-input-group-addon-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-input-group-addon-color);
  display: flex;
  font-size: var(--x-input-font-size);
  line-height: var(--x-input-line-height);
  margin-bottom: 0;
  padding: var(--x-input-padding-y) var(--x-input-padding-x);
  text-align: center;
  white-space: nowrap;
}

.input-group > .input-group-prepend > .input-group-text,
.input-group > .input-group-append:not(:last-child) > .input-group-text,
.input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-radius: var(--x-border-radius) 0 0 var(--x-border-radius);
}

.input-group > .input-group-append > .input-group-text,
.input-group > .input-group-prepend:not(:first-child) > .input-group-text,
.input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child) {
  border-radius: 0 var(--x-border-radius) var(--x-border-radius) 0;
}
