/* -----------------------------------------------------------------------------
  DATATABLE
*/

.data-table {
  .custom-select {
    min-width: rem(70);
  }

  .icon-invalid {
    position: relative;
    right: rem(-3);
    top: rem(-1);
  }

  td.comment,
  td.debtor-confirmation-comment {
    max-width: rem(250);
  }
}
