/* -----------------------------------------------------------------------------
  IMPORTS
*/

// Helpers
@import "x_scss_helpers/mixins";
@import "x_scss_helpers/functions";
@import "variables";

// Layout and components
@import "root";
@import "alert";
@import "badge";
@import "buttons";
@import "breadcrumbs";
@import "button-group";
@import "card";
@import "columns";
@import "dropdown";
@import "forms";
@import "grid";
@import "images";
@import "list-group";
@import "modal";
@import "nav";
@import "navbar";
@import "pagination";
@import "progress";
@import "spinners";
@import "tables";
@import "toasts";
@import "tooltip";
@import "transitions";
@import "type";
@import "utilities";

@import "datatables";
@import "fancybox";
@import "file-tree";
@import "calendar";

/* -----------------------------------------------------------------------------
  DEFAULTS
*/

*,
*::before,
*::after {
  box-sizing: border-box;
}

/* -----------------------------------------------------------------------------
  HEADER
*/

.color-animation {
  background: var(--x-primary);
}

.color-header {
  @extend .color-animation;
  height: rem(6);
}

/* -----------------------------------------------------------------------------
  MAIN
*/

html {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

main {
  display: block;
  flex: 1 0 auto;
}

/* -----------------------------------------------------------------------------
  LOGIN
*/

.login {
  display: grid;
  grid-template-columns: 1fr;
}

.login-item {
  --x-linear-scale-min-value: var(--x-grid-min-gutter-unitless);
  --x-linear-scale-max-value: var(--x-grid-max-gutter-unitless);

  @include linear-scale((padding-left, padding-right));
  margin: 0 auto;
  min-width: var(--x-body-min-width);
  width: 100%;
}

.login-item-sm {
  max-width: rem(400);
}

.login-item-md {
  max-width: rem(550);
}

.login-item-lg {
  max-width: rem(900);
}

.login-header {
  display: grid;

  @include media-breakpoint-up(sm) {
    grid-template-columns: repeat(2, auto);
  }
}

/* -----------------------------------------------------------------------------
  FULL HEIGHT
*/

.container-full-height-wrapper {
  display: flex;
  flex-direction: column;
}

.container-min-height-item {
  flex: 0 0 auto;
}

.container-full-height-item {
  flex: 1 0 auto;
}


/* -----------------------------------------------------------------------------
  DASHBOARD
*/

.dashboard {
  .indicator {
    border-radius: 50%;
    display: block;
    flex: 0 0 rem(16);
    height: rem(16);
    width: rem(16);
  }
}

/* -----------------------------------------------------------------------------
  DATATABLE SELECT
*/

tr {
  &.selected {
    background-color: rgba(144, 173, 37, 0.8);

    &:hover td {
      background-color: rgba(144, 173, 37, 1);
    }

    &:active td {
      background-color: rgba(144, 173, 37, 1);
    }
  }
}


/* -----------------------------------------------------------------------------
  ANALYSE
*/

.table-analyse {
  border-bottom: 0;

  > :not(caption) > * > * {
    padding: calc(var(--x-table-cell-padding-sm) / 1.5);

    &:first-child {
      padding-left: calc(var(--x-table-cell-padding-sm) * 2);
    }

    &:last-child {
      padding-right: calc(var(--x-table-cell-padding-sm) * 2);
    }
  }
}

.table-analyse-empty {
  border: 0 !important; // stylelint-disable declaration-no-important
}

.table-analyse-entry {
  border-left: var(--x-table-border-width) solid var(--x-table-border-color);
  border-right: var(--x-table-border-width) solid var(--x-table-border-color);
}

.table-analyse-summary {
  border-left: rem(2) solid var(--x-table-border-color);
  border-right: rem(2) solid var(--x-table-border-color);
}

.table-analyse-header {
  border-left: var(--x-table-border-width) solid var(--x-table-border-color);
  border-right: var(--x-table-border-width) solid var(--x-table-border-color);
  border-top: var(--x-table-border-width) solid var(--x-table-border-color) !important;
}

.table-analyse-total {
  border: rem(2) solid var(--x-table-border-color);
}

.table-analyse-bg {
  background: var(--x-analyse-bg-color) !important;
}

.table-analyse-rows {
  text-align: right;
}

.press_board {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader {
  z-index: 999999;
  position: absolute;
  left: 50%;
  top: 50%;
  width: rem(120);
  height: rem(120);
  margin: rem(-76) 0 0 rem(-76);
  border: rem(16) solid #f3f3f3;
  border-radius: 50%;
  border-top: rem(16) solid green;
  border-right: rem(16) solid blueviolet;
  border-bottom: rem(16) solid orange;
  border-left: rem(16) solid blueviolet;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

.loader_text {
  z-index: 999999;
  position: absolute;
  left: 50%;
  top: 54%;
  width: rem(120);
  height: rem(120);
  margin: rem(-76) 0 0 rem(-76);
  text-align: center;
  font-weight: bold;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
