/* -----------------------------------------------------------------------------
  NAV
*/

.nav {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

.nav-link {
  display: block;
  padding: rem(6) var(--x-spacer);

  &:hover,
  &:focus {
    text-decoration: none;
  }

  &.disabled {
    color: var(--x-gray-600);
    cursor: default;
    pointer-events: none;
  }
}

.nav-pills {
  .nav-link {
    border-radius: var(--x-border-radius);
  }

  .nav-link.active {
    background-color: var(--x-primary);
    color: var(--x-white);
    fill: var(--x-white);
  }
}

/* -----------------------------------------------------------------------------
  TABS
*/

.nav-tabs {
  border-bottom: var(--x-nav-tabs-border-width) solid var(--x-nav-tabs-border-color);

  .nav-item {
    margin-bottom: calc(var(--x-nav-tabs-border-width) * -1);
    margin-left: calc(var(--x-spacer) / 4);
    margin-right: calc(var(--x-spacer) / 4);

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  .nav-link {
    border: var(--x-nav-tabs-border-width) solid transparent;
    border-top-left-radius: var(--x-nav-tabs-border-radius);
    border-top-right-radius: var(--x-nav-tabs-border-radius);

    &:hover {
      border-color: var(--x-nav-tabs-link-hover-border-color);
    }

    &.disabled {
      background-color: transparent;
      border-color: transparent;
      color: var(--x-nav-link-disabled-color);
    }
  }

  .nav-link.active,
  .nav-item.show .nav-link {
    background-color: var(--x-nav-tabs-link-active-bg-color);
    border-color: var(--x-nav-tabs-link-active-border-color);
    color: var(--x-nav-tabs-link-active-color);
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: calc(var(--x-nav-tabs-border-width) * -1);
  }
}

.tab-content {
  > .tab-pane {
    display: none;
  }

  > .active {
    display: block;
  }
}
