/* -----------------------------------------------------------------------------
  LABELS
*/

label,
.label {
  --x-linear-scale-min-value: var(--x-default-min-font-size);
  --x-linear-scale-max-value: var(--x-default-max-font-size);

  @include linear-scale((font-size));
  cursor: pointer;
  display: inline-block;
  line-height: var(--x-input-line-height);
  margin-bottom: calc(var(--x-input-padding-y) + var(--x-input-border-width));
}
