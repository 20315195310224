/* -----------------------------------------------------------------------------
  BADGE
*/

.badge {
  background-color: var(--x-badge-bg-color);
  border-radius: var(--x-border-radius);
  color: var(--x-badge-color);
  display: inline-block;
  font-size: 75%;
  line-height: 1;
  padding: rem(4) rem(6);
  text-align: center;
  vertical-align: baseline;
  white-space: nowrap;

  @at-root a#{&} {
    &:hover {
      text-decoration: none;
    }

    &:focus,
    &.focus {
      box-shadow: 0 0 0 rem(3) var(--x-badge-box-shadow-color);
      outline: 0;
    }
  }

  &:empty {
    display: none;
  }
}

.badge-pill {
  border-radius: rem(10);
  padding-left: rem(10);
  padding-right: rem(10);
}

.badge-indicator {
  border-radius: rem(10);
  display: inline-block;
  height: rem(10);
  padding: 0;
  width: rem(10);
}

@each $color, $value in $colors {
  .badge-#{$color} {
    @include badge-variant($value);
  }
}
