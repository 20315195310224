/* -----------------------------------------------------------------------------
  BUTTON GROUP
*/

.btn-group {
  display: inline-flex;
  position: relative;
  vertical-align: middle;

  > .btn {
    flex: 1 1 auto;
    position: relative;

    &:active,
    &.active,
    &:focus,
    &:hover {
      z-index: 1;
    }
  }

  // Prevent double borders when buttons are next to each other
  > .btn:not(:first-child) {
    margin-left: calc(var(--x-button-border-width) * -1);
  }

  // Reset rounded corners
  > .btn:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
  }

  > .btn:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0;
  }
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  .input-group {
    width: auto;
  }
}
