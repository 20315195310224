/* -----------------------------------------------------------------------------
  ALERT
*/

.alert {
  background-color: var(--x-alert-bg-color);
  border: var(--x-border-width) solid var(--x-alert-border-color);
  border-radius: var(--x-border-radius);
  color: var(--x-alert-color);
  margin-bottom: var(--x-alert-margin-bottom);
  padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
  position: relative;

  hr {
    border-top-color: var(--x-alert-hr-border-color);
  }
}

.alert-heading {
  color: inherit;
}

.alert-link {
  color: var(--x-alert-anchor-color);
}

.alert-dismissible {
  .close {
    color: inherit;
    fill: var(--x-alert-anchor-color);
    padding: var(--x-alert-padding-y) var(--x-alert-padding-x);
    position: absolute;
    right: 0;
    top: 0;
  }
}

@each $color, $value in $colors {
  .alert-#{$color} {
    @include alert-variant(color-level($value, -10), color-level($value, -10), color-level($value, 6));
  }
}
