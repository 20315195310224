:root {
  @each $color, $value in $colors {
    --x-#{$color}: #{$value};
  }

  --x-border-radius: #{rem(5)};
  --x-border-width: #{rem(1)};

  // Spacing

  --x-spacer: #{rem(8)};

  // Body

  --x-body-bg-color: var(--x-white);
  --x-body-color: var(--x-gray-900);

  // Linear scale

  --x-linear-scale-min-width: var(--x-break-sm-unitless);
  --x-linear-scale-max-width: var(--x-break-xl-unitless);

  // Typography

  --x-default-min-font-size: 14;
  --x-default-max-font-size: 15;

  --x-h1-min-font-size: 24;
  --x-h1-max-font-size: 29;
  --x-h2-min-font-size: 20;
  --x-h2-max-font-size: 24;
  --x-h3-min-font-size: 16;
  --x-h3-max-font-size: 21;
  --x-h4-min-font-size: 14;
  --x-h4-max-font-size: 16;
  --x-h5-min-font-size: 14;
  --x-h5-max-font-size: 16;

  --x-small-font-size: 87.5%;
  --x-line-height-base: 1.5;
  --x-sub-sup-font-size: #{em(12)};

  --x-input-font-size-sm: #{rem(14)};
  --x-input-font-size-lg: #{rem(18)};
  --x-input-font-size: #{rem(16)};

  --x-anchor-color: var(--x-tertiary);
  --x-anchor-hover-color: #{darken($tertiary, 10%)};
  --x-anchor-focus-color: var(--x-secondary);

  // Breakpoints

  --x-body-min-width-unitless: #{$body-min-width};
  --x-break-sm-unitless: #{$break-sm};
  --x-break-md-unitless: #{$break-md};
  --x-break-lg-unitless: #{$break-lg};
  --x-break-xl-unitless: #{$break-xl};

  --x-body-min-width: #{rem($body-min-width)};
  --x-break-sm: #{rem($break-sm)};
  --x-break-md: #{rem($break-md)};
  --x-break-lg: #{rem($break-lg)};
  --x-break-xl: #{rem($break-xl)};

  // Container

  --x-container-xl-max-width: #{rem(1940)};

  // Grid

  --x-grid-min-gutter-unitless: 15;
  --x-grid-max-gutter-unitless: 30;
  --x-grid-min-gutter: calc(var(--x-grid-min-gutter-unitless) / 16);
  --x-grid-max-gutter: calc(var(--x-grid-max-gutter-unitless) / 16);

  // Input

  --x-input-border-width: #{rem(1)};

  // Range

  --x-input-range-thumb-height: #{rem(16)};
  --x-input-range-thumb-width: #{rem(16)};
  --x-input-range-track-height: #{rem(8)};
  --x-input-range-track-width: #{rem(8)};

  --x-input-range-thumb-disabled-bg-color: var(--x-gray-500);
  --x-input-range-track-bg-color: var(--x-gray-400);

  // Select

  --x-select-indicator-padding: #{rem(16)};

  // Text

  --x-form-text-margin-top: #{rem(4)};
  --x-form-feedback-margin-top: #{rem(4)};

  // Input group

  --x-input-group-addon-bg-color: var(--x-gray-200);
  --x-input-group-addon-border-color: var(--x-input-border-color);
  --x-input-group-addon-color: var(--x-input-color);

  // Button

  --x-button-padding-y: #{rem(6)};
  --x-button-padding-x: #{rem(10)};
  --x-button-border-width: #{rem(1)};

  // Fieldset / Legend

  --x-legend-margin-bottom: #{rem(8)};
  --x-legend-min-font-size: 14;
  --x-legend-max-font-size: 16;

  --x-input-control-indicator-size: #{rem(16)};
  --x-input-control-gutter: #{rem(8)};

  --x-input-border-width: #{rem(1)};
  --x-input-padding-x: #{rem(12)};
  --x-input-padding-y: #{rem(6)};
  --x-input-line-height: 1.5;
  --x-input-height-border: calc(var(--x-input-border-width) * 2);
  --x-input-height-inner: calc(var(--x-input-line-height) * 1em + var(--x-input-padding-y) * 2);
  --x-input-height: calc(var(--x-input-line-height) * 1em + var(--x-input-padding-y) * 2 + var(--x-input-height-border));
  --x-input-bg-color: var(--x-white);
  --x-input-border-color: var(--x-gray-500);
  --x-input-color: var(--x-gray-700);
  --x-input-disabled-bg-color: var(--x-gray-200);
  --x-input-focus-bg-color: var(--x-white);
  --x-input-focus-border: var(--x-primary);
  --x-input-focus-box-shadow-color: #{rgba($primary, 0.5)};
  --x-input-focus-color: var(--x-gray-700);
  --x-input-placeholder-color: var(--x-gray-600);
  --x-input-plaintext-color: var(--x-body-color);

  // Pagination

  --x-pagination-active-bg-color: var(--x-tertiary);
  --x-pagination-active-border-color: #{darken($tertiary, 5%)};
  --x-pagination-active-color: var(--x-white);
  --x-pagination-bg-color: var(--x-gray-100);
  --x-pagination-border-color: var(--x-gray-400);
  --x-pagination-color: var(--x-dark);
  --x-pagination-disabled-bg-color: var(--x-gray-100);
  --x-pagination-disabled-border-color: var(--x-gray-400);
  --x-pagination-disabled-color: var(--x-gray-600);
  --x-pagination-focus-border-color: var(--x-tertiary);
  --x-pagination-focus-box-shadow-color: #{rgba($tertiary, 0.5)};
  --x-pagination-hover-bg-color: var(--x-gray-300);
  --x-pagination-hover-border-color: var(--x-gray-500);
  --x-pagination-hover-color: var(--x-dark);
  --x-pagination-line-height: 1.5;
  --x-pagination-padding-x: #{rem(12)};
  --x-pagination-padding-y: #{rem(7)};

  // Table

  --x-table-bg-color: transparent;
  --x-table-color: var(--x-body-color);
  --x-table-border-color: var(--x-gray-500);
  --x-table-border-width: #{rem(1)};
  --x-table-cell-padding-sm: var(--x-spacer);
  --x-table-cell-padding: calc(var(--x-spacer) * 2);

  --x-table-head-bg-color: var(--x-gray-200);
  --x-table-head-color: var(--x-gray-700);

  --x-table-hover-bg-color-factor: 0.075;
  --x-table-hover-bg-color: var(--x-gray-100);
  --x-table-hover-color: var(--x-gray-900);

  --x-table-striped-bg-color-factor: 0.05;
  --x-table-striped-bg-color: var(--x-gray-200);
  --x-table-striped-color: var(--x-body-color);

  // Alert

  --x-alert-margin-bottom: #{rem(16)};
  --x-alert-padding-x: #{rem(12)};
  --x-alert-padding-y: #{rem(12)};

  // Dropdown

  --x-dropdown-bg-color: var(--x-white);
  --x-dropdown-border-color: var(--x-gray-400);
  --x-dropdown-border-radius: var(--x-border-radius);
  --x-dropdown-border-width: #{rem(1)};
  --x-dropdown-box-shadow-color: #{rgba($black, 0.175)};
  --x-dropdown-color: var(--x-body-color);
  --x-dropdown-divider-bg-color: var(--x-gray-200);
  --x-dropdown-divider-margin-y: calc(var(--x-spacer) / 2);
  --x-dropdown-header-color: var(--x-gray-600);
  --x-dropdown-item-padding-x: #{rem(24)};
  --x-dropdown-item-padding-y: #{rem(4)};
  --x-dropdown-link-active-bg-color: var(--x-white);
  --x-dropdown-link-active-color: var(--x-body-color);
  --x-dropdown-link-color: var(--x-gray-900);
  --x-dropdown-link-disabled-color: var(--x-gray-600);
  --x-dropdown-link-hover-bg-color: var(--x-white);
  --x-dropdown-link-hover-color: var(--x-tertiary);
  --x-dropdown-padding-y: #{rem(8)};

  // Spinners

  --x-spinner-color: var(--x-secondary);
  --x-spinner-width: #{rem(32)};
  --x-spinner-height: #{rem(32)};
  --x-spinner-border-width: #{rem(4)};
  --x-spinner-animation-speed: 0.75s;
  --x-spinner-width-sm: #{rem(16)};
  --x-spinner-height-sm: #{rem(16)};
  --x-spinner-border-width-sm: #{rem(3)};

  // Card

  --x-card-bg-color: var(--x-white);
  --x-card-border-color: #{rgba($black, 0.125)};
  --x-card-border-radius: #{rem(8)};
  --x-card-border-width: var(--x-border-width);
  --x-card-cap-bg-color: #{rgba($black, 0.03)};
  --x-card-spacer-x: calc(var(--x-spacer) * 2);
  --x-card-spacer-y: var(--x-spacer);

  // Tooltip

  --x-tooltip-arrow-color: var(--x-tertiary);
  --x-tooltip-arrow-height: #{rem(8)};
  --x-tooltip-arrow-width: #{rem(12)};
  --x-tooltip-padding-x: #{rem(6)};
  --x-tooltip-padding-y: #{rem(6)};
  --x-tooltip-margin: 0;

  // Progress bars

  --x-progress-bar-bg-color: var(--x-secondary);
  --x-progress-bar-color: var(--x-white);
  --x-progress-bg-color: var(--x-gray-200);
  --x-progress-height: #{rem(16)};
  --x-progress-bar-animation-timing: 1s linear infinite;

  // Navs

  --x-nav-link-padding-x: #{rem(16)};
  --x-nav-link-padding-y: #{rem(8)};
  --x-nav-link-disabled-color: var(--x-gray-700);

  --x-nav-tabs-border-color: var(--x-gray-500);
  --x-nav-tabs-border-width: #{rem(1)};
  --x-nav-tabs-border-radius: var(--x-border-radius);
  --x-nav-tabs-link-hover-border-color: var(--x-gray-400) var(--x-gray-400) var(--x-nav-tabs-border-color);
  --x-nav-tabs-link-active-color: var(--x-black);
  --x-nav-tabs-link-active-bg-color: var(--x-body-bg-color);
  --x-nav-tabs-link-active-border-color: var(--x-nav-tabs-border-color) var(--x-nav-tabs-border-color) var(--x-nav-tabs-link-active-bg-color);

  // Navbar

  --x-navbar-nav-link-padding-x: #{rem(12)};
  --x-navbar-padding-x: 0;
  --x-navbar-padding-y: #{rem(8)};
  --x-navbar-active-color: var(--x-black);
  --x-navbar-brand-color: var(--x-black);
  --x-navbar-brand-hover-color: #{rgba($black, 0.9)};
  --x-navbar-color: var(--x-black);
  --x-navbar-disabled-color: #{rgba($black, 0.3)};
  --x-navbar-hover-color: var(--x-tertiary);
  --x-navbar-toggler-color: var(--x-dark);

  // Breadcrumbs

  --x-breadcrumb-margin-bottom: #{rem(10)};
  --x-breadcrumb-item-padding-x: #{rem(5)};
  --x-breadcrumb-divider-color: var(--x-gray-800);
  --x-breadcrumb-active-color: var(--x-gray-800);

  // Toasts

  --x-toast-font-size: #{rem(14)};
  --x--toast-background-color: var(--x-white);
  --x-toast-border-color: var(--x-gray-400);
  --x-toast-border-width: 0;
  --x-toast-header-background-color: var(--x-gray-200);
  --x-toast-header-border-color: var(--x-gray-400);
  --x-toast-header-color: var(--x-gray-600);
  --x-toast-max-width: #{rem(350)};
  --x-toast-padding-x: #{rem(8)};
  --x-toast-padding-y: #{rem(4)};

  // DataTables

  --x-datatable-loader-bg-color: #{transparentize($tertiary, 0.9)};
  --x-datatable-group-bg-color: #{transparentize($info, 0.9)};

  // FullCalendar

  --x-fullcalendar-non-business-bg-color: #{transparentize($gray-200, 0.5)};
  --x-fullcalendar-highlight-bg-color: #{transparentize($tertiary, 0.9)};
  --x-fullcalender-popover-box-shadow-color: #{rgba($black, 0.5)};

  // Analyse
  --x-analyse-bg-color: #{transparentize($info, 0.9)};
}
